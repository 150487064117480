.app-header {
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  /* color: white; */
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.align-middle {
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.margin-10 {
  margin: 10px;
}

.m-tb-10 {
  margin: 10px 0;
}

.mr-10 {
  margin-right: 10px;
}

.container {
  padding: 20px;
}

.table-title {
  font-size: 18px;
  font-weight: bold;
  padding: 15px 0 0 10px;
}

.create-expire-on {
  padding: 0 10px;
  font-size: 18px;
}

.create-expire-on .title {
  font-size: 16px;
  padding: 2px 0 10px 0;
}

/* Override */
table.bp4-html-table th,
.bp4-running-text table th,
table.bp4-html-table td,
.bp4-running-text table td {
  vertical-align: middle;
}

.select-button {
  width: 100%;
  justify-content: space-between;
}
/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
